app-select-input,
app-group-select-input {
    .select {
        &.active-placeholder {
            color: $grey-800;
        }

        &__wrapper {
            display: flex;
            align-items: center;
            position: relative;
        }
    }

    .form-control.select {
        appearance: none;
        padding-right: 1.7rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

app-basic-input,
app-select-input,
app-text-area,
app-file-input,
app-group-select-input {
    .custom-input {
        border: 1px solid $tertiary;

        &:focus {
            border-color: $secondary;
            box-shadow: 0 0 0 0.1rem $gold-200;
        }

        &::placeholder {
            color: $tertiary;
        }
    }

    .invalid-control {
        border-color: $red;
    }
}

app-basic-input,
app-select-input {
    .custom-input {
        height: 2.5rem;
    }
}

app-file-input {
    .file-input {
        &__file {
            opacity: 0;
            position: absolute;
            z-index: -1;
        }

        &__label {
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;

            .bi,
            span {
                display: inline-block;
                margin-right: 0.5rem;
            }

            .bi.bi-trash {
                margin-left: auto;
            }
        }
    }
}

app-checkbox-input,
app-radio-input {
    .form-check-input {
        margin-top: 1px;
        &:checked {
            border-color: $secondary;
            background-color: $secondary;
        }

        &:focus {
            border-color: $secondary;
            box-shadow: 0 0 0 0.2rem $gold-200;
        }

        &:disabled:checked {
            background-color: $tertiary;
            border-color: $tertiary;
        }
    }
}

app-basic-input {
    i.bi {
        &.bi-search {
            position: absolute;
            left: 1rem;
            font-size: 1rem;
        }

        &.bi-x {
            position: absolute;
            right: 1rem;
            cursor: pointer;
        }
    }

    .input-wrapper {
        display: flex;
        align-items: center;
        position: relative;
    }

    input[type='search']::-webkit-search-cancel-button {
        display: none;
    }

    input.form-control.search-input {
        padding-left: 3rem;
        padding-right: 2rem;
    }
}
