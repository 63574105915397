app-dropdown {
    .dropdown {
        .btn {
            i.bi {
                transition: all 200ms ease;
                &.rotate {
                    transform: rotate(-180deg) translateY(2px);
                }
            }
        }
        .content {
            margin-top: 10px;
            position: absolute;
            z-index: 1;
            display: flex;
            flex-direction: column;
            background-color: white;
            border: 1px solid $grey;
            @extend .br-l;
            @extend .py-xs;
            @extend .px-s;

            & > * {
                cursor: pointer;
                @extend .py-xs;
                i::before {
                    @extend .mr-xs;
                }
            }
        }
    }
}
