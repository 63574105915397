//  Make the typeface available
//  1. declare the font-face
//  2. create the utility classes
//
//  @param $family Font family
//  @param $filename Name of the file
//  @param $fontname Name of the font in the css
@mixin typeface($family, $filename, $fontname, $shortname, $weight) {
    @if $family != null {
        $filepath: '/assets/fonts/' + $family + '/' + $filename;

        @font-face {
            font-family: $fontname;
            font-weight: $weight;
            font-style: normal;
            src: url($filepath + '.eot') format('embedded-opentype'), url($filepath + '.woff') format('woff'),
                url($filepath + '.woff2') format('woff2'), url($filepath + '.ttf') format('truetype'),
                url($filepath + '.otf') format('opentype');

            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    .f-#{$shortname} {
        font-family: $fontname;
        font-weight: $weight;
    }
}
