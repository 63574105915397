app-upload-logo {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
    .form {
        max-width: 400px;
    }

    app-file-input {
        display: block;
        @extend .mb-s;
        max-width: 400px;
    }
    img {
        object-fit: contain;
        max-width: 100%;
        max-height: 400px;
        display: block;
        margin: auto;
        margin-bottom: 12px;
    }

    .button-wrapper {
        display: flex;
        gap: 1rem;

        button.btn {
            display: block;
            margin: auto;
        }
    }
}
