app-alert {
    .alert {
        @extend .py-m;
        position: fixed;
        min-width: 250px;
        height: 6rem;
        width: 40%;
        opacity: 0;
        bottom: 2rem;
        left: 50%;
        overflow: hidden;
        border-bottom: 1rem solid transparent;
        transform: translate(-50%);
        transition: all 300ms ease-in-out;
        z-index: 100;

        .flex-wrapper {
            @extend .mb-xs;
            display: flex;
            align-items: center;
        }

        i.bi::before {
            @extend .pr-xs;
        }

        h4 {
            margin: 0;
            font-style: normal;
            @extend .mb-xs;
            color: inherit;
        }

        p {
            margin: 0px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        @for $i from 0 through 2 {
            &.active.alert--position-#{$i} {
                opacity: 1;
                transform: translate(-50%, -($i * 110%));
            }
        }

        &:hover {
            cursor: pointer;
        }
    }
}
