app-upload-packshot {
    display: flex;
    justify-content: center;

    .form {
        max-width: 300px;
    }

    app-file-input {
        display: block;
        @extend .mb-s;
        max-width: 400px;
    }
    img {
        object-fit: contain;
        max-width: 100%;
        max-height: 300px;
        margin-bottom: 12px;
    }

    button.btn {
        display: block;
        margin: auto;
    }
}
