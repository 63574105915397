app-pre-test-table,
app-invoices-table,
app-new-announcements-table,
app-announcements-table,
app-pretest-translations-table,
app-announcement-translations-table,
app-users-rights-table,
app-assign-new-planning-form,
app-planning-index-table,
app-result-validation-table,
app-planning-table-summary,
app-planning-group-table,
app-discounts,
app-report-assignation-table,
app-results-and-reports-table,
app-report-redaction-table {
    width: 100%;

    .table-wrapper {
        @extend .mx-xl;
        @extend .mb-xl;
    }

    .p-datatable .p-datatable-thead > tr > th {
        @extend .px-s;
        @extend .py-xxs;
        @extend .f-s;
        color: $primary;
        line-height: 150%;
        text-align: center;
    }

    .table {
        width: 100%;
    }

    .colgroup {
        .col-element {
            width: 200px;
        }

        .col--checkbox {
            width: 60px;
        }

        .col--small {
            width: 140px;
        }

        .col--wide {
            width: 400px;
        }
    }

    .p-datatable .p-datatable-thead {
        .header__row {
            height: 75px;

            .header__cell {
                &.header__checkbox {
                    text-align: center;
                }

                i.bi {
                    @extend .px-xxs;
                    font-size: inherit;
                }
            }
        }
    }

    .p-datatable .p-sortable-column .p-sortable-column-icon {
        color: $primary;
        margin: 0;
        vertical-align: inherit;
        font-size: inherit;
    }

    .p-datatable .p-datatable-tbody {
        .body {
            &__row {
                height: 80px;
                font-size: 0.9rem;

                * {
                    font-size: inherit;
                }

                i.bi {
                    font-size: 1rem;

                    &.no-padding {
                        padding: 0;
                    }
                }
            }

            &__cell {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &:hover {
                    overflow: visible;
                    white-space: normal;
                    word-wrap: break-word;
                }

                &.center {
                    text-align: center;
                }

                .flex-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
                }

                a {
                    display: flex;
                    align-items: center;

                    i.bi {
                        &::before {
                            @extend .pr-xxs;
                        }
                    }
                }

                i.bi {
                    &.bi-link,
                    &.bi-link::before {
                        font-size: 1.2rem;
                    }

                    &.bi-circle-fill,
                    &.bi-circle-fill::before {
                        font-size: 0.7rem;
                    }

                    &.bi-star,
                    &.bi-star-fill {
                        @extend .pr-xxs;
                    }
                }
            }
        }
    }

    // Checkbox style
    .p-checkbox {
        .p-checkbox-box.p-highlight {
            border-color: $gold;
            background: $gold;
        }

        &:not(.p-checkbox-disabled) {
            .p-checkbox-box {
                &:hover {
                    border-color: $gold;
                }

                &.p-highlight:hover {
                    border-color: $gold;
                    background: $gold;
                }
            }
        }
    }
}

app-pre-test-table {
    .cn-code-cell {
        > * {
            text-overflow: ellipsis;
            overflow: hidden;
            margin: auto;
        }

        &:hover {
            > a {
                flex-direction: column;
            }
        }

        .cn-chapter {
            font-family: $f-heading-bold;
            @extend .mt-none;
            @extend .mb-xxs;
        }

        .no-cn-code {
            justify-content: center;
        }
    }

    a {
        i.bi {
            &::before {
                @extend .pr-none;
            }
            &.cn-status {
                @extend .p-none;
            }
        }

        &.cn-code {
            > * {
                @extend .pr-xxs;
            }

            .cn-title {
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}
