////////////////////////////////////////////////////////////////////////////////////////////
//
//      Global Style
//
////////////////////////////////////////////////////////////////////////////////////////////

html {
    height: 100%;
}

body {
    height: 100%;
    font-family: $f-body-regular;
    @extend .f-m;
    color: $black;

    &.modal-opened,
    &.overflow-hidden {
        overflow: hidden;
    }
}

body * {
    outline: none;
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $f-heading-semibold;
    color: $blue;
}

h1 {
    @extend .f-xxl;
}

h2 {
    @extend .f-xl;
}

h3 {
    @extend .f-l;
}

h4 {
    font-style: italic;
}

img {
    -ms-interpolation-mode: bicubic;
}

/* Btns & icons */

a,
a:visited {
    color: $primary;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: $secondary;
    }
}

ul {
    ::marker {
        color: $secondary;
    }
}

.btn {
    @extend .px-xl;
    @extend .py-s;
    @extend .f-s;
    @extend .br-xxxl;
    @extend .bw-s;
    @extend .bs-solid;

    min-height: 3rem;
    transition: all 200ms;
    text-decoration: none;
    border: 1px solid transparent;

    &:disabled,
    &.disabled {
        background-color: $tertiary;
        color: $white;
        border: 1px solid $tertiary;
        cursor: not-allowed;
    }

    i::before {
        margin-right: 5px;
        margin-left: 5px;
    }

    &-primary {
        background-color: $primary;
        color: $white;
        border-color: $primary;

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):hover {
            background-color: $white;
            color: $primary;
        }

        &:not(:disabled):not(.disabled):focus {
            transform: scale(1.05);
        }

        &:visited {
            color: $white;

            &:hover,
            &:focus,
            &:active {
                background-color: $white;
                color: $primary;
            }
        }
    }

    &-secondary {
        background-color: $white;
        color: $primary;
        border-color: $primary;

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):hover {
            background-color: $white;
            color: $gold;
        }

        &:not(:disabled):not(.disabled):focus {
            transform: scale(1.05);
        }

        &:visited {
            color: $primary;

            &:hover,
            &:focus,
            &:active {
                background-color: $white;
                color: $gold;
            }
        }
    }

    &-tertiary {
        background-color: $gold;
        color: $white;
        border-color: $gold;

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):hover {
            background-color: $gold-600;
        }

        &:not(:disabled):not(.disabled):focus {
            transform: scale(1.05);
        }

        &:visited {
            color: $white;

            &:hover,
            &:focus,
            &:active {
                background-color: $gold-600;
            }
        }
    }

    &-danger {
        background-color: $white;
        color: $red;
        border-color: $red;

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):hover {
            background-color: $red;
            color: $white;
        }

        &:not(:disabled):not(.disabled):focus {
            transform: scale(1.05);
        }
    }

    &-green {
        background-color: $green;
        border-color: $green;
        color: $primary;

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):hover {
            background-color: $white;
        }
    }

    &--small {
        min-height: inherit;
        padding: 8px 10px;
        border-radius: 5px;
    }

    &-delete-icon {
        @extend .br-xl;
        padding: 2px;
        min-height: auto;
        width: 1.5rem;
        height: 1.5rem;

        > i.bi-x {
            padding: 0;

            &::before {
                margin: 0;
            }
        }
    }
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border: none;
    border-radius: 100px;
    &-blue {
        background-color: $blue;
        color: white;
        &:visited {
            color: white;
        }
    }
    &-gold {
        background-color: $gold;
        color: white;
        &:visited {
            color: white;
        }
    }
    &-grey {
        background-color: $grey;
        color: $black;
        &:visited {
            color: $black;
        }
    }
}

i.bi {
    font-size: 1.2rem;

    &.bi-circle-fill {
        font-size: 0.8rem;
    }

    &.bi-star,
    &.bi-star-fill {
        color: $secondary;
    }

    &.ok {
        color: $green;
    }

    &.missing,
    &.not-ok {
        color: $red;
    }

    &.check {
        color: $orange;
    }
}

/*  UTILITY CLASSES */
.with-icon {
    display: flex;
    align-items: center;
}

.clickable {
    cursor: pointer;
}

.highlight-text {
    color: $blue;
}

.text--danger {
    color: $red;
}

/* FORM */
.form {
    .form-row {
        display: flex;
        justify-content: space-between;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        @extend .mb-l;
        label {
            @extend .mb-xs;
        }
    }

    .form-control {
        background-clip: padding-box;
        @extend .px-s;
        @extend .bw-s;
        @extend .bs-solid;
        color: $black;
        border-color: $tertiary;
        border-radius: 0px;
        width: 100%;

        &::placeholder {
            color: $tertiary;
        }

        &:focus {
            color: inherit;
            background-color: inherit;
            border-color: $gold-300;
            outline: 0;
            box-shadow: none;
        }

        &.invalid-control {
            border-color: $red;
        }

        &.valid-control {
            border-color: $green;
        }
    }

    fieldset {
        @extend .p-m;
        @extend .mb-l;

        border: 1px solid $secondary;

        legend {
            @extend .f-l;
            @extend .p-s;
            color: $primary;
        }
    }

    textarea.form-control {
        height: 10em;
    }

    .error-message {
        @extend .f-s;
        color: $red;
        @extend .pt-s;
    }

    .confirm-message {
        @extend .f-s;
        color: $green;
        @extend .pt-s;
    }

    app-validation-feedback {
        .success-feedback,
        .error-feedback {
            @extend .py-xxs;
        }

        small {
            display: flex;
            align-items: center;

            i.bi {
                @extend .pr-xxs;
                font-size: 80%;
            }
        }

        .success-feedback {
            color: $green;
        }

        .error-feedback {
            color: $red;

            &.is-group-validation {
                display: flex;
                flex-direction: column;
            }
        }
    }

    &__actions {
        @extend .my-m;
    }
}

/* ANIMATIONS */
.blink {
    animation: blink 0.5s linear infinite;
}
