app-tabs {
    .tabs-wrapper {
        @extend .mb-m;
        @extend .px-xxl;

        @include media-down('m') {
            padding: 0 2rem;
        }
    }

    .tabs {
        display: flex;
        padding: 0;
        list-style-type: none;
        align-items: center;

        @include media-down('m') {
            justify-content: center;
            flex-direction: column;
            background-color: $blue;

            &.hidden {
                display: none;
            }
        }
    }

    .tab {
        @extend .br-xxs;
        padding: 1rem 1.5rem;
        border-bottom: 1px solid $gold;
        height: 50px;
        white-space: nowrap;

        a {
            text-decoration: none;
        }

        &.selected {
            border: 1px solid $gold;

            > a {
                color: $gold;
            }
        }

        @include media-down('m') {
            width: 100%;
            &.selected {
                border-top: none;
            }

            > a {
                color: $white;
            }
        }
    }

    .mobile-menu {
        display: none;
        color: $blue;
        font-size: 2.5rem;

        @include media-down('m') {
            display: block;
        }
    }
}
