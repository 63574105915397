////////////////////////////////////////////////////
//
//              1. Grid
//
////////////////////////////////////////////////////

@include grid-utility('xxs',  $grid-xxs);
@include grid-utility('xs',   $grid-xs);
@include grid-utility('s',    $grid-s);
@include grid-utility('m',    $grid-m);
@include grid-utility('l',    $grid-l);
@include grid-utility('xl',   $grid-xl);
@include grid-utility('xxl',  $grid-xxl);


.p-none { padding: 0; }
.px-none{ padding-left: 0; padding-right: 0; }
.py-none{ padding-top: 0; padding-bottom: 0; }
.pt-none { padding-top: 0; }
.pr-none { padding-right: 0; }
.pb-none { padding-bottom: 0; }
.pl-none{ padding-left: 0; }


.m-none {  margin: 0; }
.mx-none {  margin-left: 0; margin-right: 0; }
.my-none {  margin-top: 0; margin-bottom: 0; }
.mt-none {  margin-top: 0; }
.mr-none {  margin-right: 0; }
.mb-none {  margin-bottom: 0; }
.ml-none {  margin-left: 0; }

.block { display: block; }
.inline { display: inline-block; }
.hidden { display: none; }

.visible { visibility: visible; }
.invisible { visibility: hidden; }

.relative { position: relative; }
.absolute { position: absolute; }
.fixed { position: fixed; }

.w-100 { width: 100%; }
.w-50 { width: 50%; }
.h-100 { height: 100%; }
.h-50 { height: 50%; }


@if $external-gutters and $external-gutters not 0
{
  .container
  {
    margin-left: $external-gutters;
    margin-right: $external-gutters;
  }
}



////////////////////////////////////////////////////
//
//              2. Flex
//
////////////////////////////////////////////////////
.flex
{
  $root: ".flex";
  display: flex; display: -ms-flexbox;

  ///////////////////////////////
  //
  //  Alignement
  //
  ///////////////////////////////
  &#{$root}-start
  {
    justify-content: flex-start;
  }

  &#{$root}-center
  {
    justify-content: center;
  }

  &#{$root}-end
  {
    justify-content: flex-end;
  }

  &#{$root}-top
  {
    align-items: flex-start;
    box-pack: start;
  }

  &#{$root}-middle
  {
    align-items: center;
    box-pack: center;
  }
  &#{$root}-bottom
  {
    align-items: flex-end;
    box-pack: end;
  }
  &#{$root}-baseline
  {
    align-items: baseline;
  }

  ///////////////////////////////
  //
  //  Wrapping
  //
  ///////////////////////////////
  &#{$root}-wrap
  {
    flex-wrap: wrap;
  }
  &#{$root}-nowrap
  {
    flex-wrap: nowrap;
  }
  &#{$root}-wrap-reverse
  {
    flex-wrap: wrap-reverse;
  }

  ///////////////////////////////
  //
  //  Distribution
  //
  ///////////////////////////////
  &#{$root}-around
  {
    justify-content: space-around;
  }

  &#{$root}-between
  {
    justify-content: space-between;
  }

  ///////////////////////////////
  //
  //  Direction
  //
  ///////////////////////////////
  &#{$root}-row
  {
    flex-direction: row;
  }

  &#{$root}-column
  {
    flex-direction: column;
  }

  ///////////////////////////////
  //
  //  Grow
  //
  ///////////////////////////////
  #{$root}-1{ flex: 1; }
  #{$root}-2{ flex: 2; }
  #{$root}-3{ flex: 3; }
}

////////////////////////////////////////////////////
//
//              3. Responsive
//
////////////////////////////////////////////////////
