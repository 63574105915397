app-user-rights {
    .menu {
        @extend .mx-xl;
        @extend .mb-xl;
        display: flex;
        gap: 0.5rem;
    }
}

app-users-filter {
    .form-check {
        @extend .ml-xl;
        @extend .py-m;
    }
}

app-users-rights-table {
    .icon,
    .btn {
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    .btn-secondary {
        border: 1px solid $blue;
        &:hover {
            border: 1px solid $gold;
        }
    }
    .user-role {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .user-role-edit {
        .form-group {
            @extend .mb-s;
            label {
                @extend .mb-xs;
            }
        }
        select {
            margin-bottom: 8px;
        }
        .btn {
            margin-right: 8px;
        }
    }
    .are-you-sure {
        display: inline-flex;
        flex-wrap: wrap;
        gap: 1rem;
    }
    .p-datatable-scrollable-header-box {
        padding-right: 0px !important;
    }
    .button-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        align-items: center;
    }
}

app-rights-memo-table {
    .container {
        overflow: auto;
    }
    table {
        box-sizing: border-box;
        border-collapse: collapse;
        td,
        th {
            @extend .p-s;
            border: 1px solid #e9ecef;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        tr:nth-child(2n + 1) {
            background-color: #f8f9fa;
        }
    }
}
