app-discounts {
    form {
        @extend .mb-m;
    }
    button {
        cursor: pointer;
    }
    .btn-primary {
        @extend .mr-xs;
    }
    .amount {
        display: flex;
        align-items: center;
        input {
            @extend .mr-xs;
            max-width: 150px;
        }
    }
    .are-you-sure {
        display: flex;
        align-items: center;
        gap: 12px;
    }
    .flex-wrapper {
        display: flex;
        align-items: center;
        span {
            @extend .ml-s;
        }
    }
}
