app-table-actions {
    .button-wrapper {
        @extend .mb-xl;
        @extend .px-xxl;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        align-items: center;
    }

    .columns-action-wrapper {
        position: relative;

        &:hover {
            ul {
                visibility: visible;
                opacity: 1;
            }
        }

        ul {
            @extend .p-m;
            @extend .m-none;
            @extend .br-m;
            visibility: hidden;
            opacity: 0;
            z-index: 3;
            list-style-type: none;
            position: absolute;
            max-height: 50vh;
            min-width: 300px;
            overflow-y: scroll;
            background-color: $white;
            transition: opacity 0.4s ease-out, visibility 0.5s ease-out;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

            .column-checkbox {
                @extend .mb-s;
                @extend .py-xxs;
                border-bottom: 1px solid $tertiary;

                &.main-check {
                    color: $blue;
                }
            }
        }
    }

    .total-records {
        color: $blue;
    }

    .active-filter-btn {
        display: flex;
        align-items: center;

        i.bi {
            font-size: inherit;
        }
    }

    .export-action {
        margin-left: auto;
    }
}
