$blue: #2b2d73;
$gold: #c5aa5f;
$grey: #efefef;
$purple: #bbb9cf;
$black: #333333;
$white: #ffffff;
$red: #f11746;
$green: #91dd74;
$orange: #ffc14b;

$blue-100: tint-color($blue, 40%) !default;
$blue-200: tint-color($blue, 30%) !default;
$blue-300: tint-color($blue, 20%) !default;
$blue-400: tint-color($blue, 10%) !default;
$blue-500: $blue;
$blue-600: shade-color($blue, 10%) !default;
$blue-700: shade-color($blue, 20%) !default;
$blue-800: shade-color($blue, 30%) !default;
$blue-900: shade-color($blue, 40%) !default;

$gold-100: tint-color($gold, 40%) !default;
$gold-200: tint-color($gold, 30%) !default;
$gold-300: tint-color($gold, 20%) !default;
$gold-400: tint-color($gold, 10%) !default;
$gold-500: $gold;
$gold-600: shade-color($gold, 10%) !default;
$gold-700: shade-color($gold, 20%) !default;
$gold-800: shade-color($gold, 30%) !default;
$gold-900: shade-color($gold, 40%) !default;

$grey-100: tint-color($grey, 40%) !default;
$grey-200: tint-color($grey, 30%) !default;
$grey-300: tint-color($grey, 20%) !default;
$grey-400: tint-color($grey, 10%) !default;
$grey-500: $grey;
$grey-600: shade-color($grey, 10%) !default;
$grey-700: shade-color($grey, 20%) !default;
$grey-800: shade-color($grey, 30%) !default;
$grey-900: shade-color($grey, 40%) !default;

$purple-100: tint-color($purple, 40%) !default;
$purple-200: tint-color($purple, 30%) !default;
$purple-300: tint-color($purple, 20%) !default;
$purple-400: tint-color($purple, 10%) !default;
$purple-500: $purple;
$purple-600: shade-color($purple, 10%) !default;
$purple-700: shade-color($purple, 20%) !default;
$purple-800: shade-color($purple, 30%) !default;
$purple-900: shade-color($purple, 40%) !default;

$primary: $blue;
$secondary: $gold;
$tertiary: $purple;
$container-grey: $grey-100;
