///////////////////////////////////////////////////
//
//              Boostrap grid
//
////////////////////////////////////////////////////

@import '~bootstrap/scss/functions';

$enable-grid-classes: true !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    xxs: 0,
    xs: 320px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
) !default;
@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
) !default;
@include _assert-ascending($container-max-widths, '$container-max-widths');

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 15px !default;
$include-column-box-sizing: true !default;

@import '~bootstrap/scss/variables';

@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';
@import '~bootstrap/scss/mixins/utilities';
@import '~bootstrap/scss/mixins/alert';
@import '~bootstrap/scss/mixins/border-radius';
@import '~bootstrap/scss/mixins/gradients';

@import '~bootstrap/scss/vendor/rfs';

@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/alert';

@import '~bootstrap/scss/utilities';

$utilities: map-merge(
    (
        'opacity': (
            property: opacity,
            class: 'op',
            values: (
                0: 0,
                25: 0.25,
                50: 0.5,
                75: 0.75,
                100: 1,
            ),
        ),
    ),
    $utilities
);

$utilities: map-get-multiple(
    $utilities,
    (
        'display',
        'order',
        'flex',
        'flex-direction',
        'flex-grow',
        'flex-shrink',
        'flex-wrap',
        'justify-content',
        'align-items',
        'align-content',
        'align-self'
    )
);

@import '~bootstrap/scss/utilities/api';
