app-login,
app-confirm-password {
    .login {
        display: flex;
        height: 100vh;
        .logo {
            position: absolute;
            top: 21px;
            left: 21px;
            max-width: 200px;
        }

        &__confirm-reminder {
            margin: 2rem;
            line-height: 150%;
        }

        &_form {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 40%;
            min-width: 500px;
            h2 {
                @extend .mb-xl;
            }
            .form {
                width: 100%;
                max-width: 400px;
            }
            .form-group {
                justify-content: flex-start;
            }
        }
        &_cover {
            flex-grow: 1;
            background-color: grey;
            background-position: center;
            background-size: cover;
            background-image: url('/assets/images/bg-login.png');
        }
    }
}
