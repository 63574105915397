app-planning-details {
    i.bi::before:not(.bi-x-circle) {
        margin-right: 0.5rem;
    }

    .back-wrapper {
        @extend .py-l;
        @extend .px-xxl;
        @extend .mb-l;

        background-color: $container-grey;
    }

    .header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .planning-subtitle {
        @extend .mx-xl;
    }

    .action-and-summary-grid {
        @extend .m-l;
        display: grid;
        grid-template-columns: 1fr 3fr;
    }

    app-planning-actions {
        .action-wrapper {
            @extend .br-l;
            border: 1px solid $blue;

            .action {
                @extend .p-m;

                &:first-child:not(:only-child) {
                    border-bottom: 1px solid $blue;
                }

                &__header {
                    width: 100%;
                }

                &__content {
                    opacity: 0;
                    visibility: hidden;
                    max-height: 0;
                    overflow: hidden;
                    transition: max-height 0.2s ease-out, opacity 0.5s ease-out, visibility 0.5s ease-out, padding 0.2s ease-out;

                    &.active {
                        opacity: 1;
                        visibility: visible;
                        max-height: 40rem;
                        padding: 1rem;
                    }
                }

                &__result {
                    .result-list {
                        @extend .p-m;
                        @extend .br-l;
                        list-style-type: none;
                        background-color: $grey-100;

                        .result-item {
                            @extend .mb-m;

                            display: flex;
                            justify-content: flex-start;
                            flex-wrap: wrap;
                            gap: 0.5rem;

                            h4 {
                                margin: 0;
                            }
                        }
                    }
                }

                .tasting-in-planning-feedback {
                    @extend .my-s;
                    color: $blue;
                }
            }
        }
    }

    app-planning-table-summary > p {
        border: 2px solid $blue;
    }

    app-planning-detailed-content {
        .detailed-content-wrapper {
            @extend .mb-xxl;
            min-height: 25rem;
        }

        .day {
            &__wrapper {
                @extend .m-xl;
                @extend .br-m;
                display: flex;
                flex-direction: column;
                border: 1px solid $blue;
            }

            &__item {
                border-bottom: 1px solid $blue;

                &:last-child {
                    border-bottom: none;
                }
            }

            &__header {
                @extend .p-m;
                display: flex;
                justify-content: space-between;
                &__title {
                    @extend .f-l;
                    font-family: $f-heading-regular;
                }
            }
        }

        .group {
            &__wrapper {
                max-height: 0;
                visibility: hidden;
                overflow: hidden;
                border-top: 1px solid $blue;

                &.apply-transition {
                    transition: all 0.2s;
                }

                &.active {
                    visibility: visible;
                }
            }

            &__item {
                border-bottom: 1px solid $blue;

                &:last-child {
                    border-bottom: none;
                }
            }

            &__header {
                @extend .p-m;
                display: flex;
                justify-content: space-between;

                &__title {
                    @extend .p-m;
                }
            }

            &__content {
                max-height: 0;
                visibility: hidden;
                overflow: hidden;

                &.apply-transition {
                    transition: all 0.2s;
                }

                &.active {
                    @extend .m-m;
                    max-height: 600px;
                    visibility: visible;
                }
            }
        }
    }
}
