@charset 'utf-8';

////////////////////////////////////////////////////
//
//              1. Typefaces
//
////////////////////////////////////////////////////
$f-heading-regular: 'NotoSans-Regular';
$f-heading-semibold: 'NotoSans-SemiBold';
$f-heading-bold: 'NotoSans-Bold';

$f-body-regular: 'NotoSans-Regular';
$f-body-medium: $f-body-regular;
$f-body-light: 'NotoSans-Light';

@include typeface('noto-new', 'NotoSans-Bold', 'NotoSans-Bold', 'bold', 400);
@include typeface('noto-new', 'NotoSans-Light', 'NotoSans-Light', 'light', 400);
@include typeface('noto-new', 'NotoSans-Regular', 'NotoSans-Regular', 'regular', 400);
@include typeface('noto-new', 'NotoSans-SemiBold', 'NotoSans-SemiBold', 'semibold', 400);

////////////////////////////////////////////////////
//
//              2. Sizes
//
////////////////////////////////////////////////////

@include font-utility('xxs', $font-xxs);
@include font-utility('xs', $font-xs);
@include font-utility('s', $font-s);
@include font-utility('m', $font-m);
@include font-utility('l', $font-l);
@include font-utility('xl', $font-xl);
@include font-utility('xxl', $font-xxl);

////////////////////////////////////////////////////
//
//              3. Alignement
//
////////////////////////////////////////////////////

.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-justify {
    text-align: justify;
}

////////////////////////////////////////////////////
//
//              4. Case
//
////////////////////////////////////////////////////

.uppercase {
    text-transform: uppercase;
}
.lowercase {
    text-transform: lowercase;
}

////////////////////////////////////////////////////
//
//              5. Styles
//
////////////////////////////////////////////////////

.italic {
    font-style: italic;
}
.oblique {
    font-style: oblique;
}

////////////////////////////////////////////////////
//
//              5. Wrap
//
////////////////////////////////////////////////////

.break-all {
    word-break: break-all;
}
.break-word {
    word-wrap: break-word;
}

.hyphenate {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

////////////////////////////////////////////////////
//
//              6. Decoration
//
////////////////////////////////////////////////////

.no-decoration {
    text-decoration: none;
}
.underline {
    text-decoration: underline;
}
.overline {
    text-decoration: overline;
}
.strikethrough {
    text-decoration: line-through;
}
