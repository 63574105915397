app-collapsible {
    .collapsible {
        &__header {
            @extend .br-xs;
            @extend .px-m;
            border: 1px solid $primary;

            button.btn {
                display: flex;
                gap: 0.5rem;
                align-items: center;
                width: 100%;
                background: none;
                color: inherit;
                border: none;
                padding: 0;
                font: inherit;
                cursor: pointer;
                outline: inherit;

                i.bi-chevron-right {
                    color: $secondary;
                }

                /* If there are several elements in the header, push the last one to the right, but not if it's the main text (caret is first child) */
                :last-child:not(:nth-child(2)) {
                    margin-left: auto;
                }
            }

            i.bi-chevron-right {
                &:before {
                    transition: transform 0.1s ease-out;
                }

                &.expanded {
                    &:before {
                        transform: rotate(90deg);
                        transition-timing-function: ease-in;
                    }
                }
            }

            &.disabled {
                p,
                span,
                button,
                i::before {
                    color: $tertiary;
                }
            }
        }

        &__content {
            overflow: hidden;
            transition: all 0.3s ease-out;
            height: 0;
            padding-left: 1rem;
            padding-right: 1rem;

            &.expanded {
                border-left: 1px solid $primary;
                border-right: 1px solid $primary;
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
        }
    }

    &:not(:last-of-type) {
        .collapsible {
            &__header {
                border-bottom: none;

                &.expanded {
                    border-bottom: 1px solid $primary;
                }
            }
        }
    }

    &:last-of-type {
        .collapsible {
            &__content {
                &.expanded {
                    border-bottom: 1px solid $primary;
                }
            }
        }
    }
}
