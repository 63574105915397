app-filters {
    .filters-actions {
        @extend .mb-xl;
        display: flex;
        justify-content: center;
        gap: 1rem;
    }

    .filters {
        @extend .mb-xxl;
        margin-top: 0;

        list-style-type: none;
    }
}

app-filter-item {
    .filter {
        &__header {
            @extend .px-l;

            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $purple;
            cursor: pointer;
            transition: padding 0.1s ease-in;

            &:hover {
                @extend .pl-xl;
            }

            .label {
                @extend .pr-m;
            }

            .filter-count {
                display: inline-flex;
                align-items: center;

                i.bi-x-lg {
                    font-size: 1rem;
                    @extend .pl-l;
                }
            }
        }

        &__content {
            @extend .px-m;
            max-height: 0;
            opacity: 0;
            visibility: hidden;
            overflow-y: scroll;
            transition: max-height 0.4s ease-in, opacity 0.3s ease-in, visibility 0.3s ease-in, padding 0.3s ease-in;

            &.expanded {
                @extend .py-l;
                max-height: 20rem;
                opacity: 1;
                visibility: visible;
            }

            .form-check-label {
                > i.bi.bi-circle-fill {
                    @extend .pl-xs;
                }
            }
        }
    }

    i.bi.bi-chevron-down {
        transition: transform 0.4s ease-out;

        &.filters-opened {
            transform: rotateX(180deg);
        }
    }
}

app-pre-test-filters,
app-new-announcements-filters,
app-announcements-filters,
app-pretest-translations-filters,
app-announcement-translations-filters,
app-invoices-filters,
app-result-validation-filters,
app-users-filter,
app-report-assignation-filters,
app-results-and-reports-filters,
app-report-redaction-filters {
    @extend .p-s;
    width: 30vw;
    max-height: 150vh;
    transition: width 0.5s ease-out, max-height 0.5s ease-out, visibility 0.3s ease-out; // overflow: visible;
    visibility: visible;

    &.collapsed {
        width: 0;
        visibility: hidden;
        max-height: 0;
        overflow: hidden;
    }
}

app-users-filter {
    width: 10vw;
}
