@import '~@ng-select/ng-select/themes/default.theme.css';

.ng-select.custom {
    .ng-select-container {
        height: 2.5rem;
        border: 1px solid $tertiary;
    }

    &.ng-invalid.ng-touched {
        .ng-select-container {
            border-color: $red;
        }
    }

    &.ng-select-focused {
        &:not(.ng-select-opened) > .ng-select-container {
            border-color: $gold;
            box-shadow: 0 0 0 0.1rem $gold-200;
        }
    }
}
