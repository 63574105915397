app-report-prompts-tool {
    .menu {
        @extend .px-xxl;
        @extend .mb-l;
        display: flex;
        gap: 0.5rem;
    }

    button {
        cursor: pointer;
    }

    .flex-wrapper {
        display: flex;
        align-items: center;
    }

    .form__actions {
        @extend .px-xxl;
        margin-top: -2rem;
    }

    label {
        color: $blue;
        margin-bottom: 0.75rem;
    }

    .form {
        display: flex;
        gap: 1rem;
        .form-control {
            &.valid-control {
                border-color: $tertiary;
            }
        }
    }

    .form textarea.form-control {
        height: 30rem;
    }
    
}

