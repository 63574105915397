app-assign-announcement-form,
app-assign-planning-form {
    .form {
        @extend .my-l;

        .announcement-definition {
            @extend .my-m;
        }
    }

    .separator {
        @extend .my-l;
        @extend .p-m;

        border-top: 1px dotted $blue;
        border-bottom: 1px dotted $blue;
        text-align: center;
    }
}

app-announcement-filters-form {
    .filter-switch-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
