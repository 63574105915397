app-results-and-reports-table {
    app-tasting-scores {
        .flex {
            justify-content: space-between;
            .scores_values {
                margin-right: 16px;
                p {
                    text-align: center;
                }
            }
        }
    }
}
