app-result-validation-stats,
app-result-validation-calibration {
    display: flex;
    justify-content: center;
    .tables {
        display: flex;
        @extend .mb-m;
    }
    table,
    th,
    td {
        border: 1px solid #e9ecef;
        border-collapse: collapse;
    }
    table:nth-child(2) {
        @extend .ml-s;
    }
    tr:nth-child(even) {
        background-color: #f8f9fa;
    }
    th,
    td {
        @extend .p-s;
    }
    th {
        color: $blue;
        font-weight: 700;
    }
    td {
        color: #495057;
    }
    .stars {
        display: flex;
        align-items: center;
        .star {
            @extend .mr-xs;
            i {
                color: $primary;
            }
        }
    }
    table {
        input[type='number'] {
            max-width: 100px;
        }
    }
    .scores {
        display: flex;
        align-items: center;
        @extend .mb-m;

        .score {
            label {
                display: block;
                @extend .mb-xs;
            }
            @extend .mr-s;
        }
        input[type='number'] {
            max-width: 150px;
        }
    }
}

app-result-validation-comments {
    .scores {
        .score {
            @extend .mb-l;
        }
        label {
            color: $primary;
            font-weight: 700;
            @extend .mb-xs;
        }
    }
    .translate {
        display: flex;
        justify-content: flex-end;
        padding: 16px 16px 0px 16px;
    }

    app-collapsible {
        .actions {
            padding: 16px;
        }
        .comments {
            display: grid;
            grid-template-columns: auto auto;
            column-gap: 12px;
            row-gap: 12px;
            @extend .p-m;
            .comment {
                border: 1px solid $grey-300;
                width: 400px;
            }
            label {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: $grey-300;
                font-weight: 700;
                @extend .mb-xs;
                @extend .p-s;
                i {
                    cursor: pointer;
                }
            }
            p {
                @extend .p-s;
            }
        }
    }
}

app-result-validation-validate-modal {
    .validation-prompt {
        @extend .mb-l;
        text-align: center;
    }

    .actions {
        display: flex;
        gap: 1rem;
        justify-content: center;
    }
}
