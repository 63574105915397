app-invoicedb {
    app-invoices-stats {
        width: 30vw;
        transition: width 0.5s ease-in-out, visibility 0.3s ease-in;

        &.collapsed {
            width: 0;
            visibility: hidden;
        }
    }

    app-invoices-stats {
        .col {
            display: flex;
            justify-content: center;
        }
        table {
            box-sizing: border-box;
            border-collapse: collapse;
            td {
                height: 80px;
                border: 1px solid #e9ecef;
                padding: 1rem 1rem;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .price {
                text-align: right;
            }
        }
    }

    app-invoice-form-invoice,
    app-invoice-form-credit-note {
        form {
            .trash-icon-wrapper {
                justify-content: center;
                .bi-trash {
                    position: relative;
                    top: 12px;
                }
            }

            .recap-wrapper {
                @extend .p-s;
                @extend .br-xs;
                background-color: $grey;
                list-style-type: none;

                .recap-item {
                    @extend .pb-s;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &:last-of-type {
                        @extend .pt-xs;
                        border-top: 1px solid $secondary;
                    }
                }
            }
        }
    }
}
