app-ingredients-list-modal-form {
    app-file-input {
        display: block;
        width: fit-content;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    .pdf-link {
        display: block;
        text-align: center;
        @extend .mb-l;
    }

    .form {
        &__actions {
            display: flex;
            gap: 16px;
        }
    }
}
