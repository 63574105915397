////////////////////////////////////////////////////////////////////////////////////////////
//
//      Shared pop-up component
//
////////////////////////////////////////////////////////////////////////////////////////////
app-modal {
    .simple-modal {
        &.open {
            display: block;
        }

        display: none;
        --gutter: 16px;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 4;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.7);
        padding-top: 100px;
        @include media-down('s') {
            padding-top: 65px;
        }

        &__content {
            min-width: 45vw;
            width: fit-content;
            height: fit-content;
            max-height: calc(90vh - 100px);
            max-width: 60vw;
            position: relative;
            overflow: hidden;
            border-radius: 4px;
            margin: 0 auto;
            background-color: #ffffff;
            display: flex;
            flex-direction: column;

            @include media-down('l') {
                min-width: 70vw;
            }

            @include media-down('m') {
                width: 90vw;
                max-width: 95vw;
                max-height: calc(98vh - 65px);
            }

            header {
                color: $black;
                padding-left: var(--gutter);

                h2 {
                    text-align: center;
                    @extend .mb-l;
                    padding: 0.5rem 3rem 2rem var(--gutter);
                    font-family: $f-heading-semibold;
                    color: $primary;
                }

                .cancel-cross {
                    z-index: 1;
                    position: absolute;
                    background-color: $white;
                    right: 1.5rem;
                    top: 1.6rem;
                    width: 32px;
                    height: 32px;
                    border: none;
                    outline: none;

                    &:hover {
                        &:before,
                        &:after {
                            background-color: $secondary;
                        }
                    }

                    &:before,
                    &:after {
                        position: absolute;
                        top: 0px;
                        left: 15px;
                        content: ' ';
                        height: 33px;
                        width: 3px;
                        background-color: $primary;
                    }

                    &:before {
                        transform: rotate(45deg);
                    }

                    &:after {
                        transform: rotate(-45deg);
                    }
                }
            }

            .modal-main {
                @extend .mb-l;
                flex: 1;
                text-align: left;
                overflow: auto;
                padding: var(--gutter);

                app-remove-option-modal,
                app-tasting-deletion-modal,
                app-delete-planning-modal,
                app-tasting-bulk-unplan-modal {
                    .actions {
                        @extend .my-xl;
                        display: flex;
                        gap: 1rem;
                    }
                }
            }
        }
    }
}
