//////////////////////////////////////////
//              MAIN NAV
//////////////////////////////////////////
app-nav {
    position: relative;
    .header {
        position: sticky;
        top: 0px;
        background-color: $white;
        z-index: 1;
    }
    .wrapper {
        @extend .px-xxl;
        @extend .py-xs;
        @extend.mb-l;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    @include media-down('l') {
        .wrapper {
            padding-left: 32px;
            padding-right: 32px;
        }
    }
    @include media-down('s') {
        .wrapper {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    /////////////////////
    //      LOGO
    /////////////////////
    .logo {
        img {
            max-width: 200px;
        }
        .logo-mobile {
            display: none;
        }
        @include media-down('m') {
            display: flex;
            img {
                max-width: 150px;
            }
        }
        @include media-down('s') {
            display: flex;
            img {
                max-width: 120px;
            }
        }
        @include media-down('xs') {
            .logo-web {
                display: none;
            }
            .logo-mobile {
                display: block;
                max-width: 40px;
            }
        }
    }

    /////////////////////
    //    MENU LINKS
    /////////////////////
    .menu {
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .btn {
            @extend .ml-xs;
        }
        @include media-down('m') {
            display: none;
        }
    }
    .menu-mobile {
        display: none;
        .icon {
            @extend .ml-xs;
        }
        @include media-down('m') {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 100%;
        }
    }

    /////////////////////
    //   BURGER ICON
    /////////////////////
    .burger {
        display: none;
        @extend .ml-s;
        .bar1,
        .bar2,
        .bar3 {
            width: 35px;
            height: 5px;
            background-color: $primary;
            margin: 6px auto;
            transition: transform 0.4s;
        }
        &.active {
            .bar1 {
                -webkit-transform: rotate(-45deg) translate(-8px, 6px);
                transform: rotate(-45deg) translate(-8px, 6px);
            }
            .bar2 {
                opacity: 0;
            }
            .bar3 {
                -webkit-transform: rotate(45deg) translate(-9px, -8px);
                transform: rotate(45deg) translate(-9px, -8px);
            }
        }

        @include media-down('m') {
            display: block;
            cursor: pointer;
        }
    }
}

//////////////////////////////////////////
//              SUB NAV
//////////////////////////////////////////
app-sub-nav {
    ::-webkit-scrollbar {
        height: 5px;
        width: 5px;
        background-color: $grey-300;
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:horizontal {
        background-color: $blue;
        border-radius: 10px;
    }
    // border: 1px solid $grey-300;
    .links {
        display: flex;
        align-items: center;
        overflow-x: scroll;
    }
    .link {
        text-decoration: none;
        text-transform: uppercase;
        transition: all 200ms;
        border-radius: 100px;
        white-space: nowrap;
        @extend .f-s;
        @extend .py-s;
        @extend .px-m;
        @extend .mr-xs;
        @extend .mb-xs;

        &:hover {
            background-color: $grey-100;
        }

        &.active {
            background-color: $grey-100;
        }
    }

    @include media-down('m') {
        ::-webkit-scrollbar {
            display: none;
        }
        ::-webkit-scrollbar-thumb:horizontal {
            display: none;
        }
        .wrapper {
            background-color: $primary;
        }
        .links {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
        }
        .link {
            width: 100%;
            color: white;
            border-radius: 0px;
            margin: 10px 0;
            &:hover {
                background-color: $blue-600;
            }
            &.active {
                background-color: $gold;
                color: white;
            }
        }
    }
}
