app-report-details {
    .back-container {
        @extend .py-l;
        @extend .px-xxl;
        @extend .mb-l;

        background-color: $container-grey;
    }

    .report-details-wrapper {
        @extend .mx-xl;
        @extend .mb-xl;
    }

    .details {
        &__list {
            display: flex;
            flex-wrap: wrap;
            gap: 2rem;
            list-style-type: none;
            padding: 0;
        }

        &__item {
            @extend .p-m;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        &__label {
            @extend .f-xl;
            color: $primary;
        }
    }

    .criteria-list {
        @extend .m-m;

        .collapsible__title {
            @extend .f-l;
            color: $primary;
        }

        .length-feedback {
            @extend .p-xxs;
            @extend .f-xs;

            background-color: $container-grey;
            align-self: flex-end;
        }

        .form .form-group label {
            @extend .mb-xxs;
        }

        .highlighted-comment-control {
            @extend .mt-l;
        }

        .jury-grid {
            @extend .m-m;
            display: flex;
            gap: 2rem;
            flex-wrap: wrap;

            .jury {
                @extend .br-m;
                display: flex;
                flex-direction: column;
                flex: 1;
                min-height: 10rem;
                max-width: 50%;
                border: 1px solid $tertiary;

                &__header {
                    @extend .p-xs;
                    display: flex;
                    gap: 0.5rem;
                    justify-content: space-between;
                    align-items: center;
                    background-color: $container-grey;
                    border-bottom: 1px solid $tertiary;

                    @include media-down('m') {
                        flex-direction: column;
                    }
                }

                &__comment {
                    @extend .p-xs;
                    @extend .f-s;

                    margin: 0;

                    &.list {
                        list-style-type: none;

                        &__item {
                            @extend .pt-none;
                        }
                    }

                    strong {
                        color: $primary;
                    }
                }
            }
        }

        .client-question {
            @extend .f-s;
            @extend .mt-none;
            @extend .mb-xxs;
        }

        .form__actions {
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;
        }
    }
}
